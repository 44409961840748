.counter {
  border: 1.5px solid rgba(227, 226, 238, 1);
  width: 112px;
  height: 36px;
  color: rgba(73, 58, 177, 1);
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
}

.counterIcon {
  fill: rgba(73, 58, 177, 1) !important;
  font-size: 12px I !important;
}
