.container {
  background: linear-gradient(rgba(220, 217, 242, 1), rgba(213, 209, 240, 1));
  border-radius: 7px !important;
}

.your_plan_text {
  color: rgba(39, 37, 34, 1);
  font-size: 12px;
  font-weight: 400;
}

.plan_name {
  font-size: 28px;
  font-weight: 500;
  color: rgba(64, 50, 154, 1);
}

.validity {
  border-left: 0.5px solid rgba(39, 37, 34, 1);
}

.validtyLabel {
  font-size: 12px;
  font-weight: 300;
  color: rgba(39, 37, 34, 1);
}

.validtyValue {
  font-weight: 500;
  font-size: 14px;
  color: rgba(64, 50, 154, 1);
}

.download > * {
  font-size: 12px !important;
}

.auto_high {
  width: 14px;
  height: 14px;
  fill: white !important;
}

.CTA {
  background-color: rgba(94, 78, 197, 1);
  color: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.emptyCTA {
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  min-height: 40px;
}

.expireWarning {
  background-color: rgba(255, 170, 0, 1);
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;
}

.expireWarning > * {
  font-size: 13px !important;
  font-weight: 400;
  color: rgba(51, 34, 0, 1);
  fill: rgba(51, 34, 0, 1) !important;
}
